import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faFacebookF,
  faYoutube,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"

import { graphql, useStaticQuery } from "gatsby"
import { Head, NewsletterForm } from "../components/index"

library.add(faFacebookF, faYoutube, faInstagram, faTwitter)

export default function Home() {
  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          bg
          logo
        }
      }
    }
  `)

  return (
    <div>
      <Head />
      <main className="relative w-screen h-screen">
        <img
          src={site.siteMetadata.bg}
          className="absolute inset-0 object-cover w-full h-full"
          alt="Net Zero Report"
        />

        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-full max-w-2xl p-12 backdrop-filter backdrop-blur-md backdrop-grayscale backdrop-brightness-50 sm:rounded-2xl">
            <a href="/">
              <img
                width="96"
                height="115"
                src={site.siteMetadata.logo}
                alt="The Net Zero Report"
                className="w-24 mx-auto"
              />
            </a>
            <h1 className="mt-6 text-3xl text-center text-white">
              Your net zero journey
              <span className="text-nz-green-500"> starts here.</span>
            </h1>
            <p className="mt-4 text-lg text-center text-gray-300">
              A weekly list of the most important stories and developments on
              the path to net zero, curated by environmental professionals.
            </p>
            <div id="newsletter-landing">
              <NewsletterForm />
            </div>
            <p className="mt-3 text-sm text-center text-gray-300">
              You may unsubscribe from our mailing list at any time.
            </p>

            <h2 className="mt-4 text-lg font-bold text-center text-white">
              Not ready to subscribe? Follow us on social media.
            </h2>

            <div className="grid justify-center grid-flow-col mt-3 text-xl gap-x-3">
              <div className="inline-block">
                <a
                  href="https://www.facebook.com/NetZeroReport"
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-400 hover:text-nz-green-500"
                >
                  <FontAwesomeIcon icon={["fab", "facebook-f"]} />
                </a>
              </div>
              <div className="inline-block">
                <a
                  href="https://www.instagram.com/netzeroreport/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-400 hover:text-nz-green-500"
                >
                  <FontAwesomeIcon icon={["fab", "instagram"]} />
                </a>
              </div>
              <div className="inline-block">
                <a
                  href="https://twitter.com/netzeroreport"
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-400 hover:text-nz-green-500"
                >
                  <FontAwesomeIcon icon={["fab", "twitter"]} />
                </a>
              </div>
              <div className="inline-block">
                <a
                  href="https://www.youtube.com/channel/UCcFxSAwWJkBS6LhAMvjoKuw"
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-400 hover:text-nz-green-500"
                >
                  <FontAwesomeIcon icon={["fab", "youtube"]} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
